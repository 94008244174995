// @flow
import React, { PureComponent } from 'react';
import { Field, change, SubmissionError } from 'redux-form';
import { MapPin } from 'react-feather';
import moment from 'moment';
import { changeRoute, getNestedProperty, toJS, getTheme } from 'helpers';
import Select from 'react-select';
import countries from 'node-countries';

import { updateYouthAthleteInfo } from 'actions/user';
import { submitEntryPayment } from 'actions/entryChargeRequest';

import {
  required,
  cityTown,
  exactLength,
  hometown,
} from 'components/Forms/Validation';
import Layout from 'components/Layout';
import BackButton from 'components/BackButton';
import FormField from 'components/FormField';
import ErrorMessage from 'components/ErrorMessage';
import { postalCode } from 'components/Forms/Validation';

import {
  EVENT_ENTRY_CHECKOUT,
  EVENT_ENTRY_REVIEW,
  EVENT_ENTRY_SEARCH,
} from 'constants/routes';
import { PAY_LATER } from 'constants/paymentTypes';
import type { EventType } from 'models/Event';

import type { DisciplineType } from 'context/SelectedDisciplines/type';

type YouthAthleteInfoPropsType = {|
  history: Object,
  handleSubmit: (() => Promise<void>) => void,
  dispatch: (T: any) => void,
  userErrorMessage: string,
  loading: boolean,
  loadingAuth: boolean,
  loadingCharge: boolean,
  charge: Object,
  initialValues: Object,
  getEntryDisciplines: Function,
  selectedDisciplines: DisciplineType[],
  event: EventType,
|};

type YouthAthleteInfoStateType = {|
  country: string,
  stateOptions: Array<Object>,
|};

const SUPPORTED_COUNTRIES = new Set(['US', 'CA', 'AU']);
const US_ZIPCODE_LENGTH = 5;
const CA_ZIPCODE_LENGTH = 6;

const labelOverrides = {
  CA: {
    stateLabel: 'Province',
    zipcodeLabel: 'Postal Code',
  },
  US: {
    stateLabel: 'State',
    zipcodeLabel: 'Zip Code',
  },
};

const countryOptions = Object.keys(countries)
  .filter((country) => SUPPORTED_COUNTRIES.has(country))
  .map((value) => ({
    value,
    label: countries[value].name,
  }));

const getStateOptions = (country) =>
  countries[country].provinces.map(({ short, name }, id) => ({
    id,
    value: short,
    label: name,
  }));

const exactlyFive = exactLength(US_ZIPCODE_LENGTH);
const exactlySix = exactLength(CA_ZIPCODE_LENGTH);

export class YouthAthleteInfo extends PureComponent<
  YouthAthleteInfoPropsType,
  YouthAthleteInfoStateType,
> {
  static defaultProps = {
    userErrorMessage: '',
  };

  constructor(props: Object) {
    super(props);

    const { initialValues } = props;
    const { country } = initialValues;
    const defaultCountry = country || 'US';

    this.state = {
      stateOptions: getStateOptions(defaultCountry),
      country: defaultCountry,
    };
  }

  componentDidMount() {
    const { history, charge, dispatch } = this.props;

    if (!charge.ERAUID) {
      history.push(EVENT_ENTRY_SEARCH);
    }

    const youthAthlete = getNestedProperty(
      'location.state.youthAthlete',
      history,
      false,
    );

    dispatch(change('youthAthleteInfo', 'hometown', youthAthlete.hometown));

    window.scrollTo(0, 0);
  }

  handleCountryChange = (selection: Object) => {
    const { dispatch } = this.props;
    const country = selection ? selection.value : 'US';

    this.setState({
      country,
      stateOptions: getStateOptions(country),
    });

    dispatch(change('legalInfo', 'state', null));
  };

  handleStateChange = (selection: Object) => {
    const { dispatch } = this.props;
    dispatch(change('youthAthleteInfo', 'state', selection.value));
  };

  submitPayLater = () => {
    const {
      dispatch,
      getEntryDisciplines,
      selectedDisciplines,
      event,
    } = this.props;
    const charge = toJS(this.props.charge);
    const { AllowClasses } = event;

    const values = {
      theme: getTheme(),
      paymentType: PAY_LATER,
      charge: {
        ...charge,
        Disciplines: getEntryDisciplines(selectedDisciplines, AllowClasses),
      },
    };
    dispatch(submitEntryPayment(values));
  };

  onSubmit = async (values: Object) => {
    const { dispatch, history } = this.props;
    const { country } = this.state;
    const { hometown, zip, street, street2, city, state, ERAUID, id } = values;

    const youthAthlete = getNestedProperty(
      'location.state.youthAthlete',
      history,
      false,
    );

    if (postalCode(zip, country)) {
      throw new SubmissionError({
        zip: 'Invalid format',
        _error: 'Invalid zip or postal code',
      });
    }

    const TaxInfoUpdateDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');

    const valuesObj = {
      youthAthleteERAUID: youthAthlete.ERAUID,
      parentERAUID: ERAUID,
      parentID: id,
      hometown,
      country,
      street,
      street2,
      city,
      state,
      zip,
      TaxInfoUpdateDate,
    };

    const response = await dispatch(updateYouthAthleteInfo(valuesObj));

    if (response && response.id) {
      const showYouthAthleteInfoViewOnGoBack = getNestedProperty(
        'location.state.showYouthAthleteInfoViewOnGoBack',
        history,
        false,
      );

      const payLater = getNestedProperty(
        'location.state.payLater',
        history,
        false,
      );

      if (payLater) {
        return this.submitPayLater();
      }

      changeRoute({
        history,
        route: EVENT_ENTRY_CHECKOUT,
        state: {
          showYouthAthleteInfoViewOnGoBack: showYouthAthleteInfoViewOnGoBack,
        },
      });
    }
  };

  goBack = () => {
    const { history } = this.props;
    const showYouthAthleteInfoViewOnGoBack = getNestedProperty(
      'location.state.showYouthAthleteInfoViewOnGoBack',
      history,
      false,
    );

    if (showYouthAthleteInfoViewOnGoBack) {
      return changeRoute({
        history,
        route: EVENT_ENTRY_REVIEW,
        state: {
          showYouthAthleteInfoViewOnGoBack: true,
        },
      });
    }

    return history.goBack();
  };

  renderField = ({
    input,
    name,
    placeholder,
    className,
    type,
    min,
    max,
    maxLength,
    onKeyPress,
    meta: { touched, error, active },
  }: Object) => {
    return (
      <FormField
        input={input}
        placeholder={placeholder}
        className={className}
        type={type}
        name={name}
        touched={touched}
        error={error}
        active={active}
        min={min}
        max={max}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
      />
    );
  };

  renderStateField = ({ input, meta: { error, touched } }: Object) => {
    const { stateOptions } = this.state;

    return (
      <>
        <Select
          onChange={this.handleStateChange}
          clearable={false}
          options={stateOptions}
          searchable={true}
          placeholder="Select"
          value={input.value}
        />
        {error && touched ? (
          <span className="validation-message">{error}</span>
        ) : (
          ''
        )}
      </>
    );
  };

  render() {
    const {
      handleSubmit,
      loading,
      loadingAuth,
      loadingCharge,
      history,
    } = this.props;
    const { country } = this.state;
    const { stateLabel = 'State', zipcodeLabel = 'Zip Code' } =
      labelOverrides[country] || {};

    const sendButton = loading ? (
      <button className="btn-main" disabled>
        Saving...
      </button>
    ) : (
      <button action="submit" className="btn-main">
        Continue
      </button>
    );

    const shouldUpdateTaxInfo = getNestedProperty(
      'location.state.isTimeToUpdateTaxInfo',
      history,
      false,
    );

    const sectionTitle = shouldUpdateTaxInfo
      ? 'Review Youth Athlete Info'
      : 'Confirm Youth Athlete Info';

    const youthAthlete = getNestedProperty(
      'location.state.youthAthlete',
      history,
      false,
    );

    const firstSectionDescription =
      'This information is for the Announcer, the Rodeo Sheets and Video Screen!';

    const secondSectionDescription =
      'Please verify Parents Mailing address is up to date! The Rodeo will use this address to send checks if need be!';

    const isLoading = loading || loadingAuth || loadingCharge;

    return (
      <Layout className="bg-dashboard" loading={isLoading}>
        <div className="grid-container legal-info-view">
          <div className="grid-x">
            <div className="small-12 large-12 desktop-only">
              <BackButton goBack={this.goBack} />
              <h2 className="section-title padtop-0 padbot-0">
                {sectionTitle}
              </h2>
            </div>
            <div className="small-12 large-6 large-offset-3 theme-content-container">
              <div className="small-12 large-12 mobile-only">
                <BackButton goBack={this.goBack} />
                <h2 className="section-title padtop-0">{sectionTitle}</h2>
              </div>
              <p>{firstSectionDescription}</p>

              <div className="legal-section-title">Athlete Name</div>
              <p>{youthAthlete.FullName}</p>
              <hr />

              <form
                onSubmit={handleSubmit(this.onSubmit)}
                className="profile-edit"
              >
                <ErrorMessage errorMessage={this.props.userErrorMessage} />

                <fieldset>
                  <div className="legal-section-title">Athlete Hometown</div>
                  <hr />
                  <div className="small-12">
                    <label htmlFor="hometown">city and state *</label>
                    <Field
                      name="hometown"
                      component={this.renderField}
                      type="text"
                      placeholder="city and state"
                      validate={[required, hometown]}
                    />
                  </div>
                  <hr className="martop-0" />
                  <p>{secondSectionDescription}</p>
                  <div className="legal-section-title">
                    Parent / Guardian Mailing Address
                  </div>
                  <hr />
                  <div className="small-12">
                    <label htmlFor="country">Country *</label>
                    <Select
                      clearable={false}
                      name="country"
                      onChange={this.handleCountryChange}
                      options={countryOptions}
                      placeholder={'Select'}
                      searchable={false}
                      value={country}
                    />
                  </div>
                  <hr className="martop-0" />
                  <div className="small-12">
                    <label htmlFor="street">
                      <MapPin size="14" className="icon-map-pin" /> Street
                      address *
                    </label>
                    <Field
                      name="street"
                      component={this.renderField}
                      type="text"
                      className="street"
                      placeholder="Street Address"
                      validate={[required]}
                    />
                  </div>
                  <hr className="martop-0" />
                  <div className="small-12">
                    <label htmlFor="street2">Apartment, Suite, Etc.</label>
                    <Field
                      name="street2"
                      component={this.renderField}
                      type="text"
                      className="street2"
                      placeholder="Apartment, suite, unit, etc."
                    />
                  </div>
                  <hr className="martop-0" />
                  <div className="grid-x grid-margin-x align-justify">
                    <div className="cell small-12 large-4 marbot-2">
                      <div className="underline-field">
                        <label htmlFor="city">City *</label>
                        <Field
                          name="city"
                          component={this.renderField}
                          type="text"
                          className="city"
                          placeholder="City / Town"
                          validate={[required, cityTown]}
                        />
                      </div>
                    </div>
                    <div className="cell small-12 large-4 marbot-2">
                      <div className="underline-field">
                        <label htmlFor="state">{stateLabel} *</label>
                        <Field
                          name="state"
                          component={this.renderStateField}
                          type="text"
                          validate={[required]}
                        />
                      </div>
                    </div>
                    <div className="cell small-12 large-4">
                      <div className="underline-field">
                        <label htmlFor="zip">{zipcodeLabel} *</label>
                        <Field
                          name="zip"
                          component={this.renderField}
                          maxLength={country === 'US' ? 5 : 6}
                          onKeyPress={(event) => {
                            if (
                              (country === 'US' && !/[0-9]/.test(event.key)) ||
                              (country === 'CA' &&
                                !/[0-9]|[A-Z]|[a-z]/.test(event.key))
                            ) {
                              event.preventDefault();
                            }
                          }}
                          type="text"
                          className="zipcode"
                          placeholder="Zip Code"
                          validate={
                            country === 'US'
                              ? [required, exactlyFive]
                              : [required, exactlySix]
                          }
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="text-center">{sendButton}</div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
