// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Field, change } from 'redux-form';
import Select from 'react-select';
import { Check } from 'react-feather';
import moment from 'moment';
import { isEmpty, get } from 'lodash';
import { withAuth0 } from '@auth0/auth0-react';

const [US, CA, AU] = AVAILABLE_COUNTRY_CODES;

import {
  required,
  name,
  email,
  password,
  postalCodeByCountry,
  emailValid,
  phoneNumberValid,
} from 'components/Forms/Validation';
import { LOGIN } from 'constants/routes';
import { phone } from 'components/Forms/Normalizer';
import { getNominatableDisciplineOptions } from 'constants/disciplines';
import { getStateOptions } from 'constants/states';
import countries from 'node-countries';
import { gendersArray } from 'constants/personal';
import { userAgeVerify } from 'helpers';
import DateOfBirthInput from 'components/DateOfBirthInput';
import ReduxFormCheckbox from 'components/Forms/ReduxFormCheckbox';
import GenderRadioButton from 'components/Forms/GenderRadioButton';
import FormField from 'components/Forms/FormField';
import NameField from 'components/NameField';
import Notification from 'components/Notification';
import UserTerms from './UserTerms';
import SignIn from './SignIn';
import { getEntryInvite } from 'actions/entryInvite';
import { hasFeatureFlag } from '../../utils/flagsmith';
import { AVAILABLE_COUNTRY_CODES } from 'constants/countries';

type RegisterUserFormPropsType = {|
  loading: boolean,
  inviteToken: string,
  errorMessage: string,
  onSubmit: (f: Function) => void,
  dispatch: (f: Function) => void,
  registration: Object,
  auth0: Object,
|};

type RegisterUserFormStateType = {|
  termsIsChecked: boolean,
  athleteIsChecked: boolean,
  parentIsChecked: boolean,
  disciplineId: ?number,
  showDisciplineError: boolean,
  showStateError: boolean,
  showAccountTypeError: boolean,
  showEmailError: boolean,
  showPhoneError: boolean,
  dateOfBirth: ?moment$Moment,
  showDateOfBirthError: boolean,
  checkedGender: string,
  GenderIsChecked: boolean,
  showGenderError: boolean,
  stateId: ?number,
  country: ?string,
  fname: string,
  lname: string,
  bdate: string,
  phnum: string,
  zcode: string,
  mdisc: string,
  gnder: string,
  addr1: string,
  addr2: string,
  city0: string,
  stat0: string,
  country0: string,
  province0: string,
|};

type DisciplineObjectType = {|
  value: ?number,
|};

type StateObjectType = {|
  value: ?number,
|};

type CountryObjectType = {|
  value: ?string,
|};

const enableAuth0 = hasFeatureFlag('auth0');

class RegisterUserForm extends PureComponent<
  RegisterUserFormPropsType,
  RegisterUserFormStateType,
> {
  state = {
    termsIsChecked: false,
    athleteIsChecked: true,
    parentIsChecked: false,
    disciplineId: null,
    showDisciplineError: false,
    showStateError: false,
    showAccountTypeError: false,
    showEmailError: false,
    showPhoneError: false,
    showDateOfBirthError: false,
    dateOfBirth: null,
    checkedGender: '',
    GenderIsChecked: false,
    showGenderError: false,
    stateId: null,
    country: US,
    fname: 'First Name*',
    lname: 'Last Name*',
    bdate: 'Birth Date*',
    phnum: 'Phone Number*',
    zcode: 'ZIP Code*',
    mdisc: 'Main Discipline*',
    gnder: 'Gender*',
    addr1: 'Address',
    addr2: 'Address Line 2',
    city0: 'City*',
    stat0: 'State*',
    country0: 'Country*',
    province0: 'Province*',
  };

  UNSAFE_componentWillMount() {
    const { registration } = this.props;

    const userInfo = get(registration, 'user');
    if (!isEmpty(userInfo)) {
      this.loadDateOfBirth(userInfo.DOB);
    }
  }

  async componentDidMount() {
    const { dispatch, registration } = this.props;
    await this.loadEntryInvite();

    const userInfo = get(registration, 'user');

    if (!isEmpty(userInfo)) {
      this.loadPreviousValuesToState(userInfo);
      this.loadUserIsParent(userInfo);
      this.loadUserIsAthlete(userInfo);
      return;
    }

    dispatch(change('registration', 'isAthlete', true));
    dispatch(change('registration', 'country', US));
  }

  componentDidUpdate(
    prevProps: RegisterUserFormPropsType,
    prevState: RegisterUserFormStateType,
  ) {
    if (prevState.country !== this.state.country) {
      this.setState((s) => ({
        zcode: s.country === US ? 'ZIP Code*' : 'Postal Code*',
      }));
    }
  }

  loadPreviousValuesToState = (userInfo: Object) => {
    this.setState({
      disciplineId: userInfo.disciplineId,
      checkedGender: userInfo.Gender,
      termsIsChecked: userInfo.terms,
      stateId: userInfo.stateId,
    });
  };

  loadDateOfBirth = (dateOfBirthString: string) => {
    const { dispatch } = this.props;
    const reformattedDOB = this.reformatDOBString(dateOfBirthString);
    const dateOfBirth = moment(reformattedDOB, 'MMDDYYYY');
    const userDOB = dateOfBirthString;
    dispatch(change('registration', 'DOB', userDOB));
    this.setState({ dateOfBirth });
  };

  reformatDOBString = (dateOfBirthString: string) => {
    const reformattedDOB = moment(dateOfBirthString)
      .format('MMDDYYYY')
      .toString();
    return reformattedDOB;
  };

  loadUserIsParent = (userInfo: Object) => {
    const { dispatch } = this.props;
    dispatch(change('registration', 'isParent', userInfo.isParent));
    this.setState({
      parentIsChecked: userInfo.isParent,
    });
  };

  loadUserIsAthlete = (userInfo: Object) => {
    const { dispatch } = this.props;
    dispatch(change('registration', 'isAthlete', userInfo.isAthlete));
    this.setState({
      athleteIsChecked: userInfo.isAthlete,
    });
  };

  loadEntryInvite = async () => {
    const { dispatch, inviteToken } = this.props;
    const entryInvitePayload = await getEntryInvite(inviteToken);
    if (!entryInvitePayload) return;

    const { firstName, lastName, email, phone } = entryInvitePayload;
    dispatch(change('registration', 'firstName', firstName));
    dispatch(change('registration', 'lastName', lastName));
    dispatch(change('registration', 'email', email));
    dispatch(change('registration', 'phone', phone));
  };

  onChangeDiscipline = (disciplineObject: DisciplineObjectType) => {
    const disciplineId = disciplineObject ? disciplineObject.value : null;
    const { dispatch } = this.props;
    dispatch(change('registration', 'disciplineId', disciplineId));
    this.setState({ disciplineId, showDisciplineError: false });
  };

  onChangeState = (stateObject: StateObjectType) => {
    const stateId = stateObject ? stateObject.value : null;
    const { dispatch } = this.props;
    dispatch(change('registration', 'stateId', stateId));
    this.setState({ stateId });
  };

  onChangeCountry = (stateObject: CountryObjectType) => {
    const countryId = stateObject ? stateObject.value : null;
    const { dispatch } = this.props;
    dispatch(change('registration', 'country', countryId));
    this.setState({ country: countryId });
  };

  onSubmit = async (e: Event) => {
    const {
      disciplineId,
      athleteIsChecked,
      parentIsChecked,
      dateOfBirth,
      GenderIsChecked,
      showEmailError,
      stateId,
    } = this.state;

    e.preventDefault();

    if (showEmailError) {
      return;
    }

    if (!athleteIsChecked && !parentIsChecked) {
      this.setState({ showAccountTypeError: true });
      return;
    }

    if (athleteIsChecked && !disciplineId) {
      this.setState({ showDisciplineError: true });
      return;
    }

    if (!stateId) {
      this.setState({ showStateError: true });
      return;
    }

    if (userAgeVerify(dateOfBirth)) {
      this.setState({ showDateOfBirthError: true });
      return;
    } else {
      this.setState({ showDateOfBirthError: false });
    }

    if (!GenderIsChecked) {
      this.setState({ showGenderError: true });
      return;
    }
    this.props.onSubmit(e);
  };

  onCheckBoxClicked = () => {
    const { termsIsChecked } = this.state;
    const { dispatch } = this.props;
    dispatch(change('registration', 'terms', !termsIsChecked));
    this.setState({ termsIsChecked: !termsIsChecked });
  };

  checkAthlete = () => {
    const { athleteIsChecked, parentIsChecked } = this.state;
    const { dispatch } = this.props;

    let error = athleteIsChecked ? true : false;
    if (parentIsChecked) {
      error = false;
    }

    dispatch(change('registration', 'isAthlete', !athleteIsChecked));

    this.setState({
      athleteIsChecked: !athleteIsChecked,
      showAccountTypeError: error,
    });
  };

  getCountryOptions = () => {
    const countryOptions = countries.JSON.flatMap((country) => {
      if (AVAILABLE_COUNTRY_CODES.includes(country.alpha2)) {
        return [
          {
            value: country.alpha2,
            label: country.name,
          },
        ];
      }
      return [];
    });
    return countryOptions;
  };

  checkParent = () => {
    const { parentIsChecked, athleteIsChecked } = this.state;
    const { dispatch } = this.props;

    let error = parentIsChecked ? true : false;
    if (athleteIsChecked) {
      error = false;
    }

    if (parentIsChecked) {
      this.setState({
        fname: 'First Name*',
        lname: 'Last Name*',
        bdate: 'Birth Date*',
        phnum: 'Phone Number*',
        zcode: 'ZIP Code*',
        mdisc: 'Main Discipline*',
        gnder: 'Gender*',
        addr1: 'Address',
        addr2: 'Address Line 2',
        city0: 'City*',
        stat0: 'State*',
        country0: 'Country*',
        province0: 'Province*',
      });
    } else {
      this.setState({
        fname: 'Parent/Guardian First Name*',
        lname: 'Parent/Guardian Last Name*',
        bdate: 'Parent/Guardian Birth Date*',
        phnum: 'Parent/Guardian Phone Number*',
        zcode: 'Parent/Guardian ZIP Code*',
        mdisc: 'Parent/Guardian Main Discipline*',
        gnder: 'Parent/Guardian Gender*',
        addr1: 'Parent/Guardian Address',
        addr2: 'Parent/Guardian Address Line 2',
        city0: 'Parent/Guardian City',
        stat0: 'Parent/Guardian State',
        country0: 'Parent/Guardian Country',
        province0: 'Parent/Guardian Province',
      });
    }

    dispatch(change('registration', 'isParent', !parentIsChecked));
    this.setState({
      parentIsChecked: !parentIsChecked,
      showAccountTypeError: error,
    });
  };

  onChangeDateOfBirth = (dateOfBirthString: string) => {
    const { dispatch } = this.props;
    const dateOfBirth = moment(dateOfBirthString, 'MMDDYYYY');
    const userDOB = dateOfBirth.format('YYYY-MM-DD').toString();
    dispatch(change('registration', 'DOB', userDOB));

    this.setState({ dateOfBirth });
  };

  openAuth0 = () => {
    const { loginWithRedirect } = this.props.auth0;
    return loginWithRedirect;
  };

  onGenderSelect = (genderSelection: SyntheticInputEvent<EventTarget>) => {
    const { dispatch } = this.props;
    const { checkedGender } = this.state;
    const { value } = genderSelection.target;

    dispatch(change('registration', 'Gender', value));

    if (checkedGender === value) {
      this.setState({ checkedGender: '' });
    }

    this.setState({
      checkedGender: value,
      GenderIsChecked: true,
      showGenderError: false,
    });
  };

  onBlurEmail = async (e_mail: string) => {
    if (await emailValid(e_mail)) {
      this.setState({ showEmailError: false });
    } else {
      if (required(e_mail) === undefined && email(e_mail) === undefined) {
        this.setState({ showEmailError: true });
      } else {
        this.setState({ showEmailError: false });
      }
    }
  };

  onBlurPhone = async (phone: string) => {
    if (await phoneNumberValid(phone)) {
      this.setState({ showPhoneError: false });
    } else {
      if (required(phone) === undefined) {
        this.setState({ showPhoneError: true });
      } else {
        this.setState({ showPhoneError: false });
      }
    }
  };

  getProvinceOptions = () => {
    const { country } = this.state;
    if (!AVAILABLE_COUNTRY_CODES.includes(country)) {
      return [];
    }
    const foundCountry = countries.getCountryByNameOrShortName(country, true);
    if (!foundCountry) {
      return [];
    }
    const provinces = foundCountry ? foundCountry.provinces : [];
    // $FlowIgnore
    const provinceOptions = provinces.map((province) => {
      return {
        value: province.short,
        label: province.name,
      };
    });
    return provinceOptions;
  };

  getTerritoriesOptions = () => {
    const { country } = this.state;
    if (!AVAILABLE_COUNTRY_CODES.includes(country)) {
      return [];
    }
    const foundCountry = countries.getCountryByNameOrShortName(country, true);
    if (!foundCountry) {
      return [];
    }

    const provinces = foundCountry ? foundCountry.provinces : [];
    // $FlowIgnore
    const provinceOptions = provinces
      .filter((p) => p.short)
      .map((province) => {
        return {
          value: province.short,
          label: province.name,
        };
      });

    return provinceOptions;
  };

  render() {
    const { loading } = this.props;
    const {
      termsIsChecked,
      showDisciplineError,
      showAccountTypeError,
      showEmailError,
      showPhoneError,
      disciplineId,
      athleteIsChecked,
      parentIsChecked,
      dateOfBirth,
      checkedGender,
      showGenderError,
      showStateError,
      stateId,
      country,
      fname,
      lname,
      bdate,
      phnum,
      zcode,
      mdisc,
      gnder,
      addr1,
      addr2,
      city0,
      stat0,
      country0,
      province0,
    } = this.state;

    return (
      <>
        <h2 className="section-title center marbot-2 padtop-1">Register</h2>
        <p>
          ALREADY HAVE A WCRA OR RET ACCOUNT?{' '}
          {!enableAuth0 ? (
            <Link to={LOGIN} className="link-gold display-inline">
              SIGN IN
            </Link>
          ) : (
            // $FlowFixMe
            <Link
              onClick={this.openAuth0()}
              className="link-gold display-inline"
              to="/#"
            >
              SIGN IN
            </Link>
          )}
        </p>
        <h5 className="section-registration-subtitle">Account details</h5>
        <hr />

        <form className="registration-form" onSubmit={this.onSubmit}>
          {this.props.errorMessage && (
            <Notification text={this.props.errorMessage} />
          )}

          <fieldset className="padbot-5">
            <label htmlFor="email">Your Email*</label>
            <Field
              name="email"
              component={(p) => <FormField {...p} />}
              type="email"
              placeholder="Email Address"
              validate={[required, email]}
              onBlur={(evt) => {
                this.onBlurEmail(evt.target.value);
              }}
            />
            {showEmailError && (
              <span className="validation-email-message">
                Email Already in Use
              </span>
            )}
            <hr className={showEmailError ? 'martop-0-email' : 'martop-0'} />
            <label htmlFor="password">Password*</label>
            <Field
              name="password"
              component={(p) => <FormField {...p} />}
              className="form-control registration"
              type="password"
              placeholder="Password"
              validate={[required, password]}
            />
            <hr className="martop-0" />
            <h5 className="section-registration-subtitle">Account Type</h5>
            <h6>Select all that apply</h6>
            <div className="form-checkbox-wrapper">
              <span
                // $FlowFixMe
                className={`input-checkbox ${athleteIsChecked && 'checked'}`}
                value="Athlete"
                onClick={this.checkAthlete}
              >
                <Check className="checkmark" />
              </span>

              <label htmlFor="Athlete" className="checkbox-label">
                Athlete
              </label>
            </div>
            <Field
              component={ReduxFormCheckbox}
              type="checkbox"
              name="athlete"
              id="athlete"
              className="hide"
            />
            <div className="form-checkbox-wrapper">
              <span
                // $FlowFixMe
                className={`input-checkbox ${parentIsChecked && 'checked'}`}
                value="Athlete"
                onClick={this.checkParent}
                data-cy="parent-guardian-checkbox"
              >
                <Check className="checkmark" />
              </span>

              <label htmlFor="Parent" className="checkbox-label">
                Parent/Guardian of a 17 year old or younger athlete
              </label>
            </div>

            <Field
              component={ReduxFormCheckbox}
              type="checkbox"
              name="parent"
              id="parent"
              className="hide"
            />
            {showAccountTypeError && (
              <span className="validation-message">
                An Account type must be selected
              </span>
            )}

            <h5 className="section-registration-subtitle">Profile Details</h5>
            <hr />
            <label htmlFor="firstName">{fname}</label>
            <NameField
              name="firstName"
              component={(p) => <FormField {...p} />}
              placeholder="First Name"
              validate={[name]}
            />

            <hr className="martop-0" />
            <label htmlFor="lastName">{lname}</label>
            <NameField
              name="lastName"
              component={(p) => <FormField {...p} />}
              placeholder="Last Name"
              validate={[name]}
            />

            <hr className="martop-0" />
            <label htmlFor="birthdate">{bdate}</label>
            <Field
              name="DOB"
              component={(p) => <FormField {...p} />}
              type="date"
              placeholder="Birthdate"
              validate={[required]}
              max={moment()
                .subtract(2, 'years')
                .format('YYYY-MM-DD')}
              min={moment()
                .subtract(100, 'years')
                .format('YYYY-MM-DD')}
              className="hide"
            />
            <DateOfBirthInput
              pickedDate={dateOfBirth}
              onChange={this.onChangeDateOfBirth}
            />
            {this.state.showDateOfBirthError && (
              <Notification text="Parents/guardians must register for athletes under 18" />
            )}
            <hr className="martop-0" />
            <label htmlFor="phone">{phnum}</label>
            <Field
              name="phone"
              component={(p) => <FormField {...p} />}
              type="tel"
              placeholder="Phone Number"
              normalize={phone}
              validate={[required]}
              onBlur={(evt) => {
                this.onBlurPhone(evt.target.value);
              }}
            />
            {showPhoneError && (
              <span className="validation-phone-message">
                Phone number already in use. Do you have an account?
              </span>
            )}
            <hr className={showPhoneError ? 'martop-0-phone' : 'martop-0'} />

            {athleteIsChecked && (
              <>
                <label htmlFor="discipline">{mdisc}</label>
                <Select
                  className="select-discipline"
                  clearable={false}
                  name="discipline"
                  onChange={this.onChangeDiscipline}
                  options={getNominatableDisciplineOptions()}
                  placeholder="Select"
                  searchable={false}
                  value={disciplineId}
                />

                {showDisciplineError && (
                  <span className="validation-message">
                    Main discipline must be selected
                  </span>
                )}
                <hr className="martop-0" />
              </>
            )}

            <div className="gender-container">
              <div className="gender-label label">{gnder}</div>
              {gendersArray.map((gender) => {
                return (
                  <Field
                    component={GenderRadioButton}
                    type="radio"
                    name="gender"
                    id="gender"
                    gender={gender}
                    className="gender-option"
                    onGenderSelect={this.onGenderSelect}
                    checkedGender={checkedGender}
                    key={gender.value}
                  />
                );
              })}
            </div>

            {showGenderError && (
              <span className="validation-message">
                Gender must be selected
              </span>
            )}

            <div>
              <h5 className="section-registration-subtitle">Address</h5>
              <span className="reg-optional">Optional</span>
            </div>
            <hr />
            <label htmlFor="address">{addr1}</label>
            <NameField
              name="address"
              component={(p) => <FormField {...p} />}
              placeholder="Address"
            />

            <hr className="martop-0" />
            <label htmlFor="address2">{addr2}</label>
            <NameField
              name="address2"
              component={(p) => <FormField {...p} />}
              placeholder="Address Line 2"
            />
            <hr className="martop-0" />
            <label htmlFor="country">{country0}</label>
            <Select
              className="select-discipline"
              clearable={false}
              name="country"
              onChange={this.onChangeCountry}
              options={this.getCountryOptions()}
              placeholder="Select"
              searchable={false}
              value={country}
            />
            <hr className="martop-0" />
            <label htmlFor="city">{city0}</label>
            <NameField
              name="city"
              validate={[required]}
              component={(p) => <FormField {...p} />}
              placeholder="City"
            />
            <hr className="martop-0" />
            <label htmlFor="zip">{zcode}</label>
            <Field
              name="zip"
              component={(p) => <FormField {...p} />}
              type="text"
              className="zipcode"
              placeholder="ZIP Code"
              validate={[required, postalCodeByCountry]}
            />
            {country === US && (
              <>
                <hr className="martop-0" />
                <label htmlFor="state">{stat0}</label>
                <Select
                  className="select-discipline"
                  clearable={false}
                  name="state"
                  onChange={this.onChangeState}
                  options={getStateOptions()}
                  placeholder="Select"
                  searchable={false}
                  value={stateId}
                />
                {showStateError && (
                  <span className="validation-message">
                    State must be selected
                  </span>
                )}
              </>
            )}
            {country === CA && (
              <>
                <hr className="martop-0" />
                <label htmlFor="province">{province0}</label>
                <Select
                  className="select-discipline"
                  clearable={false}
                  name="state"
                  onChange={this.onChangeState}
                  options={this.getProvinceOptions()}
                  placeholder="Select"
                  searchable={false}
                  value={stateId}
                />
                {showStateError && (
                  <span className="validation-message">
                    Province must be selected
                  </span>
                )}
              </>
            )}
            {country === AU && (
              <>
                <hr className="martop-0" />
                <label htmlFor="province">{province0}</label>
                <Select
                  className="select-discipline"
                  clearable={false}
                  name="state"
                  onChange={this.onChangeState}
                  options={this.getTerritoriesOptions()}
                  placeholder="Select"
                  searchable={false}
                  value={stateId}
                />
                {showStateError && (
                  <span className="validation-message">
                    Territory must be selected
                  </span>
                )}
              </>
            )}
            <hr className="martop-0" />

            <div className="terms-container">
              <div className="form-checkbox-wrapper">
                <span
                  // $FlowFixMe
                  className={`input-checkbox ${termsIsChecked && 'checked'}`}
                  onClick={this.onCheckBoxClicked}
                  data-cy="terms-checkbox"
                >
                  <Check className="checkmark" />
                </span>
                <UserTerms />
              </div>
              <Field
                component={ReduxFormCheckbox}
                type="checkbox"
                name="terms"
                id="terms"
                validate={[required]}
                className="hide"
              />
            </div>
          </fieldset>
          <div className="text-center">
            {loading ? (
              <button className="btn-main marbot-5" type="submit" disabled>
                Registering...
              </button>
            ) : (
              <button className="btn-main" type="submit" action="submit">
                {parentIsChecked ? 'Continue' : 'Register'}
              </button>
            )}
            <br />
            <SignIn />
          </div>
        </form>
      </>
    );
  }
}

export default enableAuth0 ? withAuth0(RegisterUserForm) : RegisterUserForm;
