export const AVAILABLE_COUNTRIES_AND_DETAILS = {
  US: { administrativeDivision: 'State', postalTerm: 'Zip' },
  CA: { administrativeDivision: 'Province', postalTerm: 'Postal Code' },
};

export const AVAILABLE_COUNTRY_CODES = ['US', 'CA', 'AU'];

export const ZIP_LENGTH_BY_COUNTRY = {
  US: 5,
  CA: 6,
  AU: 4,
};
