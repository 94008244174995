import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import Select from 'react-select';
import { MapPin, Image } from 'react-feather';
import { WithContext as ReactTags } from 'react-tag-input';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import BackButton from 'components/BackButton';
import FormField from 'components/FormField';
import GenderRadioButton from 'components/Forms/GenderRadioButton';
import NameField from 'components/NameField';
import { phone } from 'components/Forms/Normalizer';
import { required, postalCodeByCountry } from 'components/Forms/Validation';
import ImageUpload from 'components/ImageUpload';
import Layout from 'components/Layout';
import Logout from 'components/Logout';
import ErrorMessage from 'components/ErrorMessage';
import { gendersArray } from 'constants/personal';
import { getDisciplineOptions } from 'constants/disciplines';
import { HOME } from 'constants/routes';
import { isInvalidBirthdate, isYouthAthlete, getURLQueryString } from 'helpers';
import moment from 'moment';
import { updateUserPhoto } from 'actions/user';
import DateOfBirthInput from 'components/DateOfBirthInput';
import withToJS from 'enhancers/withToJS';
import StateSelect from 'components/StateSelect';
import { hasFeatureFlag } from '../../utils/flagsmith';
import { Grid } from '@material-ui/core';
import countries from 'node-countries';
import { AVAILABLE_COUNTRY_CODES } from 'constants/address';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  loadingAuth: PropTypes.bool,
  location: PropTypes.object,
  user: PropTypes.object.isRequired,
  userErrorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  userToken: PropTypes.string,
};

const defaultProps = {
  userErrorMessage: '',
  location: null,
};

const EditProfile = (props) => {
  const {
    dispatch,
    fetchUser,
    history,
    handleSubmit,
    loading,
    loadingAuth,
    location,
    user,
    userErrorMessage,
    userToken,
  } = props;
  const socialTagsArray = user.socialTags
    ? user.socialTags.split(',').map((tag) => ({
        id: tag,
        text: tag,
      }))
    : [];

  const [disciplineId, setDisciplineId] = useState(
    user ? user.disciplineId : null,
  );
  const formValues = useSelector((state) =>
    get(state, `form.profile.values`, {}),
  );
  const { isAthlete } = user;
  const [socialTags, setSocialTags] = useState(socialTagsArray);
  const [imageProfileBlob, setImageProfileBlob] = useState(null);
  const [isFinishingProfile, setIsFinishingProfile] = useState(false);
  const [showDisciplineError, setShowDisciplineError] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(
    user.DOB ? moment(user.DOB, 'YYYY-MM-DD') : null,
  );
  const [checkedGender, setCheckedGender] = useState(user.Gender);
  const [showDateOfBirthError, setShowDateOfBirthError] = useState(false);
  const [showAdultDOBDecreaseError, setShowAdultDOBDecreaseError] = useState(
    false,
  );
  const [showYouthDOBIncreaseError, setShowYouthDOBIncreaseError] = useState(
    false,
  );
  const enableDOBVerified = hasFeatureFlag('dob-verified');

  useEffect(() => {
    fetchUser(userToken);

    const finishRegistration = getURLQueryString(
      location,
      'finish-registration',
    );

    if (finishRegistration && finishRegistration.toString() === 'true') {
      window.scrollTo(0, 0);
      setIsFinishingProfile(true);
    }
  }, []);

  const onChangeDiscipline = (disciplineObject) => {
    const disciplineId = disciplineObject ? disciplineObject.value : '';
    setDisciplineId(disciplineId);
  };

  const onSocialTagDelete = (i) => {
    setSocialTags(socialTags.filter((tag, index) => index !== i));
  };

  const onSocialTagAdd = (unfilteredTag) => {
    if (!unfilteredTag) return;

    const tagText = unfilteredTag.text.replace(/[^a-zA-Z0-9]/g, '');
    const tag = { id: tagText, text: tagText };

    if (socialTags.length > 11) return;

    const existingKeys = socialTags.map((tag) => tag.id.toLowerCase());
    if (existingKeys.indexOf(tag.id.toLowerCase()) >= 0) return;

    setSocialTags([...socialTags, tag]);
  };

  const onSocialTagDrag = (tag, currPos, newPos) => {
    const socialTags = [...socialTags];
    const newTags = socialTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setSocialTags(newTags);
  };

  const onSocialTagBlur = (tagText) => {
    if (tagText.length == 0) return;

    onSocialTagAdd({ id: tagText, text: tagText });
  };

  const onSubmit = async (values) => {
    const { location, onSubmit, history } = props;
    const { isAthlete } = user;

    const socialTagArray = [];
    Object.keys(socialTags).map((key) =>
      socialTagArray.push(socialTags[key].text),
    );

    if (isInvalidBirthdate(dateOfBirth)) {
      return setShowDateOfBirthError(true);
    }

    if (
      isYouthAthlete(dateOfBirth) &&
      !isYouthAthlete(moment(user.DOB, 'YYYY-MM-DD'))
    ) {
      return setShowAdultDOBDecreaseError(true);
    }

    if (
      !isYouthAthlete(dateOfBirth) &&
      isYouthAthlete(moment(user.DOB, 'YYYY-MM-DD'))
    ) {
      return setShowYouthDOBIncreaseError(true);
    }

    if (!disciplineId && isAthlete) {
      return setShowDisciplineError(true);
    }

    const updatedAt = new Date();
    const newValues = {
      ...values,
      updatedAt,
      ERAUID: user.ERAUID,
      disciplineId,
      socialTags: socialTagArray.join(','),
      DOB: dateOfBirth.format('YYYY-MM-DD'),
      Gender: checkedGender,
    };

    // this is only used during registration, not editing
    delete newValues.signature;
    delete newValues.isDisclosable;

    const response = await onSubmit(newValues);
    setShowDateOfBirthError(false);
    setShowDisciplineError(false);
    setShowAdultDOBDecreaseError(false);
    setShowYouthDOBIncreaseError(false);

    if (response && response.id && isFinishingProfile) {
      const redirect = getURLQueryString(location, 'redirect') || HOME;
      history.push(redirect);
    }
  };

  const getCountryOptions = () => {
    const countryOptions = countries.JSON.flatMap((country) => {
      if (AVAILABLE_COUNTRY_CODES.includes(country.alpha2)) {
        return [
          {
            value: country.alpha2,
            label: country.name,
          },
        ];
      }
      return [];
    });
    return countryOptions;
  };

  const getStateOptions = () => {
    const country = formValues.country || user.country;
    if (!AVAILABLE_COUNTRY_CODES.includes(country)) {
      return [];
    }
    const foundCountry = countries.getCountryByNameOrShortName(country, true);
    return foundCountry
      ? foundCountry.provinces
          .filter((p) => p.short)
          .map((province) => ({
            value: province.short,
            label: province.name,
          }))
      : [];
  };

  const stateProvinceField = () => {
    if (formValues.country === 'US') {
      return (
        <>
          <label htmlFor="state">State</label>
          <Field name="state" component={StateSelect} />
        </>
      );
    } else if (formValues.country === 'CA') {
      return (
        <>
          <label htmlFor="state">Province</label>
          <Field
            name="state"
            component={StateSelect}
            options={getStateOptions() || []}
          />
        </>
      );
    } else if (formValues.country === 'AU') {
      return (
        <>
          <label htmlFor="state">Territory</label>
          <Field
            name="state"
            component={StateSelect}
            options={getStateOptions() || []}
          />
        </>
      );
    }
  };

  const getProfileImage = () => {
    return imageProfileBlob ? imageProfileBlob : user.photoFileName;
  };

  const handleImageSelected = async (images) => {
    const image = images[0];
    setImageProfileBlob(image.preview);
    try {
      await dispatch(updateUserPhoto(image));
      // Clear blob to force use image returned from backend
      setImageProfileBlob('');
    } catch (error) {
      setImageProfileBlob('');
    }
  };

  const onChangeDateOfBirth = (dateOfBirthString) => {
    const dateOfBirth = moment(dateOfBirthString, 'MMDDYYYY');
    setDateOfBirth(dateOfBirth);
  };

  const onGenderSelect = (genderSelection) => {
    const { value } = genderSelection.target;

    dispatch(change('profile', 'Gender', value));

    if (checkedGender === value) {
      setCheckedGender('');
      return;
    }
    setCheckedGender(value);
  };

  const isYouth = isYouthAthlete(dateOfBirth);

  return (
    <Layout className="bg-dashboard" loading={loading || loadingAuth}>
      <div className="grid-container">
        <div className="grid-x">
          <div className="small-12 large-12 desktop-only">
            {isFinishingProfile ? (
              <Logout />
            ) : (
              <BackButton goBack={history.goBack} />
            )}
            <h2 className="section-title padtop-0">
              {isFinishingProfile ? 'Complete Profile' : 'Edit Profile'}
            </h2>
          </div>
          <div className="small-12 large-6 large-offset-3 theme-content-container">
            <div className="small-12 large-12 mobile-only">
              {isFinishingProfile ? (
                <Logout />
              ) : (
                <BackButton goBack={history.goBack} />
              )}
              <h2 className="section-title padtop-0">
                {isFinishingProfile ? 'Complete Profile' : 'Edit Profile'}
              </h2>
              <span className="color-gold">*</span> Indicates required fields
            </div>
            <hr />

            <form onSubmit={handleSubmit(onSubmit)} className="profile-edit">
              <ErrorMessage
                errorMessage={
                  showDisciplineError && 'Main discipline must be selected'
                }
              />
              <ErrorMessage
                errorMessage={
                  showDateOfBirthError && 'Please enter a valid birthdate'
                }
              />
              <ErrorMessage
                errorMessage={
                  showAdultDOBDecreaseError &&
                  'Adult athletes must be 18 years of age or older.'
                }
              />
              <ErrorMessage
                errorMessage={
                  showYouthDOBIncreaseError &&
                  'Youth athletes cannot be over the age of 17. If an athlete is 18 or older, they must have their own separate account.'
                }
              />
              <ErrorMessage errorMessage={userErrorMessage} />

              <fieldset>
                <label htmlFor="firstName">
                  <span className="color-gold">*</span>First Name
                </label>
                <NameField
                  name="firstName"
                  component={FormField}
                  placeholder="First Name"
                  validate={[required]}
                />
                <hr />
                <label htmlFor="lastName">
                  <span className="color-gold">*</span>Last Name
                </label>
                <NameField
                  name="lastName"
                  component={FormField}
                  placeholder="Last Name"
                  validate={[required]}
                />
                <hr />
                <Grid container spacing={2} alignItems="center">
                  {enableDOBVerified && user.DOBVerified && (
                    <Grid item>
                      <LockOutlinedIcon className="color-black field-icon" />
                    </Grid>
                  )}
                  <Grid item>
                    <label htmlFor="birthdate">
                      <span className="color-gold">*</span>Birthdate
                    </label>
                    <DateOfBirthInput
                      disabled={enableDOBVerified && user.DOBVerified}
                      pickedDate={dateOfBirth}
                      onChange={onChangeDateOfBirth}
                    />
                  </Grid>
                </Grid>

                <hr />
                {isAthlete && (
                  <>
                    <label htmlFor="discipline">
                      <span className="color-gold">*</span>Your Main Discipline
                    </label>
                    <Select
                      className="select-discipline"
                      clearable={false}
                      name="discipline"
                      onChange={onChangeDiscipline}
                      options={getDisciplineOptions()}
                      placeholder="Select"
                      searchable={false}
                      value={disciplineId}
                    />
                    {showDisciplineError && (
                      <span className="validation-message">
                        Main discipline must be selected
                      </span>
                    )}
                    <hr />
                  </>
                )}
                <div className="small-12">
                  <label htmlFor="phone">
                    <span className="color-gold">*</span>Your Phone
                  </label>
                  <Field
                    name="phone"
                    component={FormField}
                    type="tel"
                    placeholder="Phone Number"
                    normalize={phone}
                    validate={[required]}
                    props={{ disabled: isYouth }}
                  />
                </div>
                <hr />
                {isAthlete && (
                  <>
                    {!isYouthAthlete(dateOfBirth) && (
                      <div>
                        <div className="small-12">
                          <label htmlFor="street">
                            <MapPin size="14" className="icon-map-pin" /> Your
                            Address
                          </label>
                          <Field
                            name="street"
                            component={FormField}
                            type="text"
                            className="street"
                            placeholder="Street Address"
                          />
                        </div>
                        <div className="small-12">
                          <label htmlFor="street2">Address Line 2</label>
                          <Field
                            name="street2"
                            component={FormField}
                            type="text"
                            className="street2"
                            placeholder="Apartment, suite, unit, etc."
                          />
                        </div>
                        <div className="grid-x">
                          <div className="small-12 large-3">
                            <label htmlFor="city">City</label>
                            <Field
                              name="city"
                              component={FormField}
                              type="text"
                              className="city"
                              placeholder="City / Town"
                            />
                          </div>
                          <div className="small-12 large-3">
                            {stateProvinceField()}
                          </div>
                          <div className="small-12 large-3">
                            <label htmlFor="zip">
                              {formValues.country === 'US'
                                ? 'Zip'
                                : 'Postal Code'}
                            </label>
                            <Field
                              name="zip"
                              component={FormField}
                              maxLength="7"
                              onKeyPress={(e) =>
                                /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i.test(
                                  e.key,
                                )
                                  ? e.preventDefault()
                                  : () => true
                              }
                              type="text"
                              className="zipcode"
                              placeholder="Zip Code"
                              validate={[required, postalCodeByCountry]}
                            />
                          </div>
                          <div className="small-12 large-3">
                            <label htmlFor="country">Country</label>
                            <Field
                              name="country"
                              options={getCountryOptions()}
                              component={StateSelect}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="small-12">
                          <label htmlFor="socialTags">
                            Social Media Hashtags
                          </label>
                          <ReactTags
                            autofocus={false}
                            name="socialTags"
                            placeholder="Social Media Hashtags"
                            maxLength="20"
                            tags={socialTags}
                            handleDelete={onSocialTagDelete}
                            handleAddition={onSocialTagAdd}
                            handleDrag={onSocialTagDrag}
                            handleInputBlur={onSocialTagBlur}
                          />
                        </div>
                        <hr />
                        <ImageUpload handleImageSelected={handleImageSelected}>
                          <div className="photo-upload-wrapper">
                            <button>Upload profile photo</button>
                            <div
                              className="photo-placeholder-wrapper"
                              style={{
                                backgroundSize: 'cover',
                                backgroundImage: `url('${getProfileImage}')`,
                              }}
                            >
                              {!getProfileImage() ? (
                                <Image className="photo-placeholder" />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </ImageUpload>
                        <hr />
                        <label htmlFor="nickname">Nickname</label>
                        <Field
                          name="nickname"
                          component={FormField}
                          type="text"
                          placeholder="Enter Here"
                        />
                        <hr />
                        <label htmlFor="hometown">Hometown</label>
                        <Field
                          name="hometown"
                          component={FormField}
                          type="text"
                          placeholder="Enter Here"
                        />
                        <hr />
                      </div>
                    )}
                    <div className="gender-container">
                      <div className="gender-label label">
                        <span className="color-gold">*</span>
                        GENDER
                      </div>

                      {gendersArray.map((gender) => {
                        return (
                          <Field
                            component={GenderRadioButton}
                            type="radio"
                            name="Gender"
                            id="Gender"
                            gender={gender}
                            className="gender-option"
                            onGenderSelect={onGenderSelect}
                            checkedGender={checkedGender}
                            key={gender.value}
                            validate={[required]}
                          />
                        );
                      })}
                    </div>
                    {!isYouthAthlete(dateOfBirth) && (
                      <div>
                        <hr />
                        <label htmlFor="careerEarnings">Career Earnings</label>
                        <Field
                          name="careerEarnings"
                          component={FormField}
                          type="text"
                          placeholder="$ Enter here"
                        />
                        <hr />
                        <label htmlFor="worldChampionshipEarned">
                          World Championships Earned
                        </label>
                        <Field
                          name="worldChampionshipEarned"
                          component={FormField}
                          type="text"
                          placeholder="Enter here"
                        />
                        <hr />
                        <div className="grid-x">
                          <div className="small-12 large-6">
                            <label htmlFor="height">Height</label>
                            <Field
                              name="height"
                              component={FormField}
                              type="text"
                              placeholder='Feet´ Inches"'
                            />
                          </div>
                          <div className="small-12 large-6">
                            <label htmlFor="weight">Weight</label>
                            <Field
                              name="weight"
                              component={FormField}
                              type="text"
                              placeholder="000 Lbs"
                            />
                          </div>
                        </div>
                        <hr />
                        <label htmlFor="facebook">Facebook</label>
                        <Field
                          name="facebook"
                          component={FormField}
                          type="text"
                          placeholder="Enter facebook url"
                        />
                        <hr />
                        <label htmlFor="twitter">Twitter</label>
                        <Field
                          name="twitter"
                          component={FormField}
                          type="text"
                          placeholder="Enter twitter url"
                        />
                        <hr />
                        <label htmlFor="instagram">Instagram</label>
                        <Field
                          name="instagram"
                          component={FormField}
                          type="text"
                          placeholder="Enter instagram url"
                        />
                      </div>
                    )}
                    <hr />
                  </>
                )}
              </fieldset>
              <div className="text-center">
                <button className="btn-main" disabled={loading} action="submit">
                  {loading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

EditProfile.propTypes = propTypes;

EditProfile.defaultProps = defaultProps;

export default withToJS(EditProfile);
